import { Contract } from 'api/contract/types';
import { Package } from 'api/packages/types';
import ClaimsTable from '../ClaimsTable';
import PMSClaimsTable from '../PMSClaimsTable';
import RenewalsTable from '../RenewalsTable';
import styles from './index.module.scss';

const TablesDisclaimer = ({
    relations,
    contractPackage,
}: {
    relations: Contract['relations'];
    contractPackage: Package;
}) => {
    return (
        <>
            {relations?.claimdb_claims?.length ||
            relations?.renewals?.length ||
            relations?.claimdb_claims?.length ? (
                <div className={styles.page}>
                    {relations?.renewals?.length ? (
                        <div className={styles.container}>
                            <RenewalsTable
                                renewals={relations.renewals}
                                contractPackage={contractPackage}
                            />
                        </div>
                    ) : null}
                    {relations?.claimdb_claims?.length ? (
                        <div className={styles.container}>
                            <ClaimsTable claims={relations?.claimdb_claims} />
                        </div>
                    ) : null}
                    {relations?.claims?.length ? (
                        <div className={styles.container}>
                            <PMSClaimsTable pmsClaims={relations.claims} />
                        </div>
                    ) : null}
                </div>
            ) : null}
        </>
    );
};
export default TablesDisclaimer;

import { Table } from '@HometreeEngineering/component-library';
import { Renewal } from 'api/contract/types';
import { Package } from 'api/packages/types';
import { Calendar } from 'assets/pandoraIcons';
import { buildRenewalsTableData } from 'features/contract/utils/tableDataBuilder';
import { useMemo } from 'react';
import styles from '../../index.module.scss';

const RenewalsTable = ({
    renewals,
    contractPackage,
}: {
    renewals: Renewal[];
    contractPackage: Package;
}) => {
    const headers = useMemo(
        () => [
            { key: 'active', value: 'Active' },
            { key: 'id', value: 'Renewal ID' },
            { key: 'package', value: 'Package' },
            { key: 'renewal_price', value: 'Renewal Price' },
            { key: 'status', value: 'Status' },
            { key: 'proposed_claim_count', value: 'Proposed Claim Count' },
            { key: 'discount_applied', value: 'Discount applied (Monthly)' },
            { key: 'saving', value: 'Saving' },
            { key: 'renewal_start_date', value: 'Renewal Start Date' },
        ],
        []
    );

    const data = useMemo(
        () => buildRenewalsTableData(renewals, contractPackage),
        [renewals, contractPackage]
    );

    return (
        <div className={styles.container}>
            <Table
                headers={headers}
                data={data}
                customStyle={styles.differentHeader}
                title="Renewals"
                icon={<Calendar />}
                headerVariant="green"
            />
        </div>
    );
};

export default RenewalsTable;

import {
    ChevronDown,
    ChevronUp,
    RadioButtonSelected,
    RadioButtonUnselected,
} from 'assets/pandoraIcons';
import { useState } from 'react';
import styles from './index.module.scss';

export interface Props {
    options: string[];
    customStyle?: string;
    selectedOption: string;
    setSelectedOption: any;
    testId?: string;
}

const Dropdown = ({ customStyle, options, selectedOption, setSelectedOption, testId }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const toggling = () => setIsOpen(!isOpen);

    const onOptionClicked = (value: string) => () => {
        setSelectedOption(value);
        setIsOpen(false);
    };

    return (
        <div className={`${styles.main} ${customStyle || ''}`}>
            <div className={styles.dropdownContainer}>
                <button
                    onClick={toggling}
                    className={styles.button}
                    type="button"
                    data-testid="dropdown-button"
                >
                    <div className={`${styles.dropdownFirstItem}`}>
                        <p
                            className={styles.selectedOption}
                            data-testid={testId || 'displayed-option'}
                        >
                            {selectedOption || options[0]}
                        </p>
                        {isOpen ? (
                            <span className={styles.icon}>
                                <ChevronUp />
                            </span>
                        ) : (
                            <span className={styles.icon}>
                                <ChevronDown />
                            </span>
                        )}
                    </div>
                </button>
                {isOpen && (
                    <ul className={styles.dropdownList}>
                        {options.map((option) => (
                            <li
                                className={`${styles.listItem} ${
                                    option === selectedOption ? styles.selected : ''
                                }`}
                                key={option}
                            >
                                <button
                                    onClick={onOptionClicked(option)}
                                    type="button"
                                    className={styles.listItemButton}
                                    data-testid="options"
                                >
                                    <p className={styles.listText}>{option}</p>
                                    {option === selectedOption ? (
                                        <span className={styles.listItemIcon}>
                                            <RadioButtonSelected />
                                        </span>
                                    ) : (
                                        <span className={styles.listItemIcon}>
                                            <RadioButtonUnselected />
                                        </span>
                                    )}
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default Dropdown;

import { ContractStatus } from 'types/enum/ContractStatus';

export const statusIdsForResinstate = [
    ContractStatus.Cancelled,
    ContractStatus.Expired,
    ContractStatus.Expired,
    ContractStatus.Lapsed,
    ContractStatus.Uncontactable,
];
export const statusIdsForCancel = [
    ContractStatus.Live,
    ContractStatus.Future,
    ContractStatus.WebsitePending,
    ContractStatus.SignatureRequired,
    ContractStatus.ExpiredDueToPaymentIssues,
    ContractStatus.Lapsed,
    ContractStatus.Uncontactable,
];
export const statusIdsForChangeDates = [
    ContractStatus.Live,
    ContractStatus.Future,
    ContractStatus.WebsitePending,
    ContractStatus.SignatureRequired,
];
export const statusIdsForActivate = [ContractStatus.Pending];

import { Button } from '@HometreeEngineering/component-library';
import {
    statusIdsForActivate,
    statusIdsForCancel,
    statusIdsForChangeDates,
    statusIdsForResinstate,
} from 'features/contract/constants';

interface Props {
    handleCancel: () => void;
    handleReinstate: () => void;
    handleActivate: () => void;
    handleChangeDate: () => void;
    isButtonsDisabled: boolean;
    status: number;
    paymentStatus?: { id: number; message: string };
}

interface ButtonType {
    customStyle?: string;
    text: string;
    isDisplayed: boolean;
    isDisabled?: boolean;
    handleClick: () => void;
    variant:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'alternative'
        | 'arrow'
        | 'text'
        | 'logout'
        | 'outlined';
    subVariant: 'cancel' | 'change' | 'edit' | undefined;
}

export const HeaderButtons = ({
    handleCancel,
    handleReinstate,
    handleActivate,
    handleChangeDate,
    isButtonsDisabled,
    status,
    paymentStatus,
}: Props) => {
    const buttons = [
        {
            text: 'Reinstate Contract',
            isDisplayed: statusIdsForResinstate.includes(status),
            handleClick: handleReinstate,
            variant: 'primary',
        },
        {
            text: 'Cancel Contract',
            isDisplayed: statusIdsForCancel.includes(status),
            handleClick: handleCancel,
            variant: 'outlined',
            subVariant: 'cancel',
        },
        {
            text: 'Change Dates',
            isDisplayed: statusIdsForChangeDates.includes(status),
            handleClick: handleChangeDate,
            variant: 'outlined',
            subVariant: 'change',
        },
        {
            text: 'Activate Contract',
            isDisplayed: statusIdsForActivate.includes(status),
            isDisabled: paymentStatus?.id === 3,
            handleClick: handleActivate,
            variant: 'outlined',
            subVariant: 'edit',
        },
    ] as ButtonType[];

    const buttonsToDisplay = buttons.filter(
        (button: ButtonType) => button.isDisplayed
    ) as ButtonType[];

    return (
        <div style={{ display: 'flex', gap: '30px' }}>
            {buttonsToDisplay.map((button: ButtonType) => (
                <Button
                    variant={button.variant}
                    subVariant={button.subVariant}
                    onClick={button.handleClick}
                    isDisabled={button.isDisabled || isButtonsDisabled}
                    key={button.text}
                >
                    {button.text}
                </Button>
            ))}
        </div>
    );
};

import { ReactElement } from 'react';
import styles from './index.module.scss';

export interface Props {
    heading: string;
    icon?: ReactElement;
    background?: 'green' | 'white';
}

const backgroundToClass = {
    white: 'bgWhite',
    green: 'bgGreen',
};

export const InfoBox: React.FC<Props> = ({ heading, icon, background = 'white', children }) => {
    return (
        <article
            className={`${styles[backgroundToClass[background] || backgroundToClass.white]} ${
                styles.infoBoxContainer
            }`}
        >
            {icon ? <span className={styles.icon}>{icon} </span> : null}
            <section className={styles.contentContainer}>
                <header className={styles.infoBoxHeading}>{heading}</header>
                <div>{children}</div>
            </section>
        </article>
    );
};
